import React, {useState} from "react";
import logo from '../assets/img/logo-1.png';

export const Header = () => {
  const [hide, setHide] = useState(false);

  return (
      <header className="template-header absolute-header navbar-center sticky-header">
        <div className="container">
          <div className="header-inner">
            <div className="header-left">
              <div className="branding-and-language-selection">
                <div className="brand-logo">
                  <a href="/">
                    <img src={logo} alt="logo"/>
                  </a>
                </div>
              </div>
            </div>
            <div className="header-right">
              <ul className="header-extra">
                <li className="d-none d-sm-block">
                  <a href="javascript:void(0)" data-toggle="modal" data-target="#search-modal"
                     className="search-btn search-border-right">
                    <i className="fas fa-search" hidden={true}></i>
                  </a>
                </li>
                <li>
                  <a onClick={e => setHide(!hide)} className="off-canvas-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="off-canvas-wrapper" hidden={hide}>
          <div className="canvas-overlay"></div>
          <div className="canvas-inner">
            <div className="canvas-logo">
              <img src={logo} alt="Canvas Logo"/>
            </div>
            <div className="about-us">
              <h5 className="canvas-widget-title">About Us</h5>
              <p>
                Sed perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam rem aperams eaque ipsa quae abillo inventore veritatis
              </p>
            </div>
            <div className="contact-us">
              <h5 className="canvas-widget-title">Contact Us</h5>
              <ul>
                <li>
                  <i className="far fa-map-marker-alt"></i>
                  354 Oakridge, Camden NJ 08102 - USA
                </li>
                <li>
                  <i className="far fa-envelope-open"></i>
                  <a href="mailto:support@ayyildiztechnology.com">support@ayyildiztechnology.com</a>
                  <a href="www.ayyildiztechnology.com" target="_blank">www.ayyildiztechnology.com</a>
                </li>
                <li>
                  <i className="far fa-phone"></i>
                  <span><a href="tel:+01234567899">+012 (345) 678 99</a></span>
                  <span><a href="tel:+8563214">+8563214</a></span>
                </li>
              </ul>
            </div>
            <a href="#" className="canvas-close">
              <i className="fal fa-times"></i>
            </a>
          </div>
        </div>
      </header>
  )
};
