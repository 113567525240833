import React, {FormEvent, FormEventHandler} from 'react';
import {Header} from "./components/Header";
import comingSoonLogo from './assets/img/coming-soon.png';

function App() {

  const handleSubmit =  (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("form submitted");
  }

  return (
      <div className="coming-soon-page">
        <Header/>

        <section className="coming-soon-area">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-10">
                <div className="coming-soon-content text-lg-left text-center">
                  <h2 className="title">Coming Soon</h2>
                  <form onSubmit={handleSubmit} className="newsletter-form-2">
                    <input type="email" placeholder="Enter Email Address"/>
                    <button type="submit"><i className="fas fa-arrow-right"></i></button>
                  </form>
                  <p className="newsletter-notice">Join With Us To Get More Update</p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-10">
                <div className="coming-soon-image text-right m-t-md-50">
                  <img src={comingSoonLogo} alt="coming soon"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
}

export default App;
